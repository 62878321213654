/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AnalyticsOrderDeleteDocument,
  AnalyticsOrderDeleteMutation,
  AnalyticsOrderDeleteMutationVariables,
  AnalyticsOrderStartDocument,
  AnalyticsOrderStartMutation,
  AnalyticsOrderStartMutationVariables,
  AnalyticsOrdersDocument,
  AnalyticsOrdersQuery,
  AnalyticsOrdersQueryVariables,
  AssistantDocument,
  AssistantQuery,
  AssistantQueryVariables,
  AssistantsDocument,
  AssistantsQuery,
  AssistantsQueryVariables,
  AssistantsWithUserPromptsDocument,
  AssistantsWithUserPromptsQuery,
  AssistantsWithUserPromptsQueryVariables,
  ChatBenchmarkingAbortDocument,
  ChatBenchmarkingAbortMutation,
  ChatBenchmarkingAbortMutationVariables,
  ChatBenchmarkingStatusDocument,
  ChatBenchmarkingStatusQuery,
  ChatBenchmarkingStatusQueryVariables,
  ChatCreateDocument,
  ChatCreateMutation,
  ChatCreateMutationVariables,
  ChatDeleteDocument,
  ChatDeleteMutation,
  ChatDeleteMutationVariables,
  ChatDocument,
  ChatQuery,
  ChatQueryVariables,
  ChatSubscriptionDocument,
  ChatSubscriptionSubscription,
  ChatSubscriptionSubscriptionVariables,
  ChatUpdateTitleDocument,
  ChatUpdateTitleMutation,
  ChatUpdateTitleMutationVariables,
  ChatsDocument,
  ChatsQuery,
  ChatsQueryVariables,
  ContentByChatDocument,
  ContentByChatQuery,
  ContentByChatQueryVariables,
  ContentByIdDocument,
  ContentByIdQuery,
  ContentByIdQueryVariables,
  ContentUpsertByChatDocument,
  ContentUpsertByChatMutation,
  ContentUpsertByChatMutationVariables,
  MessageCreateDocument,
  MessageCreateMutation,
  MessageCreateMutationVariables,
  MessageCreateSyncDocument,
  MessageCreateSyncMutation,
  MessageCreateSyncMutationVariables,
  MessageDocument,
  MessageQuery,
  MessageQueryVariables,
  MessageSubscriptionDocument,
  MessageSubscriptionSubscription,
  MessageSubscriptionSubscriptionVariables,
  MessageUpdateDocument,
  MessageUpdateMutation,
  MessageUpdateMutationVariables,
  MessagesDocument,
  MessagesQuery,
  MessagesQueryVariables,
  UndoChatDeleteDocument,
  UndoChatDeleteMutation,
  UndoChatDeleteMutationVariables,
  UserPromptCreateDocument,
  UserPromptCreateManyDocument,
  UserPromptCreateManyMutation,
  UserPromptCreateManyMutationVariables,
  UserPromptCreateMutation,
  UserPromptCreateMutationVariables,
  UserPromptDeleteAllUserOwnedPromptsDocument,
  UserPromptDeleteAllUserOwnedPromptsMutation,
  UserPromptDeleteAllUserOwnedPromptsMutationVariables,
} from '@/@generated/graphql';
import {
  GraphqlFetcherArgs,
  Service,
  useClientSWR,
  useClientSWRMutation,
  useWebsocketSubscription,
} from '@unique/next-commons/swr';
import { Client, Sink } from 'graphql-ws';
import { SWRConfiguration } from 'swr';
import { SWRMutationConfiguration } from 'swr/mutation';
import { CHAT_TITLE_MAX_LENGTH } from '@/lib/constants/chatTitleMaxLength';

const truncateChatTitle = (title: string) => {
  if (typeof title !== 'string') return '';
  return title.length > CHAT_TITLE_MAX_LENGTH
    ? `${title.slice(0, CHAT_TITLE_MAX_LENGTH - 3)}...`
    : title;
};

export function createManyUserPromptsMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      UserPromptCreateManyMutation,
      any,
      GraphqlFetcherArgs<any>,
      UserPromptCreateManyMutationVariables
    >
  >,
) {
  return useClientSWRMutation<UserPromptCreateManyMutation, UserPromptCreateManyMutationVariables>(
    key,
    UserPromptCreateManyDocument,
    options,
    Service.NODE_CHAT,
  );
}

export function deleteAllUserOwnedPromptsMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      UserPromptDeleteAllUserOwnedPromptsMutation,
      any,
      GraphqlFetcherArgs<any>,
      UserPromptDeleteAllUserOwnedPromptsMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
    UserPromptDeleteAllUserOwnedPromptsMutation,
    UserPromptDeleteAllUserOwnedPromptsMutationVariables
  >(key, UserPromptDeleteAllUserOwnedPromptsDocument, options, Service.NODE_CHAT);
}

export function useChatQuery(
  variables?: ChatQueryVariables,
  options?: Partial<SWRConfiguration<ChatQuery>>,
) {
  const resp = useClientSWR<ChatQuery, ChatQueryVariables>(getUseChatQueryKey(variables), options);
  if (resp?.data?.chat?.title) {
    resp.data.chat.title = truncateChatTitle(resp.data.chat.title);
  }
  return resp;
}

export function useChatsQuery(
  variables?: ChatsQueryVariables,
  options?: Partial<SWRConfiguration<ChatsQuery>>,
) {
  const resp = useClientSWR<ChatsQuery, ChatsQueryVariables>(
    getUseChatsQueryKey(variables),
    options,
  );
  if (resp?.data?.chats) {
    resp.data.chats.forEach((chat) => {
      chat.title = truncateChatTitle(chat.title);
    });
  }
  return resp;
}

export function useMessagesQuery(
  variables?: MessagesQueryVariables,
  options?: Partial<SWRConfiguration<MessagesQuery>>,
) {
  return useClientSWR<MessagesQuery, MessagesQueryVariables>(
    // If variables exist OR are not passed to the function, execute query. Otherwise (e.g. is null), don't execute.
    variables || variables === undefined ? getUseMessagesQueryKey(variables) : null,
    options,
  );
}

export function useMessageQuery(
  variables?: MessageQueryVariables,
  options?: Partial<SWRConfiguration<MessageQuery>>,
) {
  return useClientSWR<MessageQuery, MessageQueryVariables>(
    // If variables exist OR are not passed to the function, execute query. Otherwise (e.g. is null), don't execute.
    variables || variables === undefined ? getUseMessageQueryKey(variables) : null,
    options,
  );
}

export function useAssistantsQuery(variables?: AssistantsQueryVariables, options?: Partial<SWRConfiguration<AssistantsQuery>>) {
  return useClientSWR<AssistantsQuery, AssistantsQueryVariables>(
    getUseAssistantsQueryKey(variables),
    options
  );
}

export function useAssistantsWithUserPromptsQuery(
  variables?: AssistantsWithUserPromptsQueryVariables,
) {
  return useClientSWR<AssistantsWithUserPromptsQuery, AssistantsWithUserPromptsQueryVariables>(
    getUseAssistantsWithUserPromptsQueryKey(variables),
  );
}

export function useAssistantQuery(
  variables?: AssistantQueryVariables,
  options?: Partial<SWRConfiguration<AssistantQuery>>,
) {
  return useClientSWR<AssistantQuery, AssistantQueryVariables>(
    // If variables exist OR are not passed to the function, execute query. Otherwise (e.g. is null), don't execute.
    variables || variables === undefined ? getUseAssistantQueryKey(variables) : null,
    options,
  );
}

export function useContentByIdQuery(
  variables?: ContentByIdQueryVariables,
  options?: Partial<SWRConfiguration<ContentByIdQuery>>,
) {
  return useClientSWR<ContentByIdQuery, ContentByIdQueryVariables>(
    // If variables exist OR are not passed to the function, execute query. Otherwise (e.g. is null), don't execute.
    variables || variables === undefined ? getUseContentByIdQueryKey(variables) : null,
    options,
    Service.NODE_INGESTION,
  );
}

export function useContentByChatQuery(
  variables?: ContentByChatQueryVariables,
  options?: Partial<SWRConfiguration<ContentByChatQuery>>,
) {
  return useClientSWR<ContentByChatQuery, ContentByChatQueryVariables>(
    // If variables exist OR are not passed to the function, execute query. Otherwise (e.g. is null), don't execute.
    variables || variables === undefined ? getUseContentByChatQueryKey(variables) : null,
    options,
    Service.NODE_INGESTION,
  );
}

export function useChatBenchmarkingStatusQuery(
  options?: Partial<SWRConfiguration<ChatBenchmarkingStatusQuery>>,
) {
  return useClientSWR<ChatBenchmarkingStatusQuery, ChatBenchmarkingStatusQueryVariables>(
    getUseChatBenchmarkingStatusQueryKey(),
    options,
  );
}

export function useChatBenchmarkingAbortMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ChatBenchmarkingAbortMutation,
      any,
      GraphqlFetcherArgs<any>,
      ChatBenchmarkingAbortMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
    ChatBenchmarkingAbortMutation,
    ChatBenchmarkingAbortMutationVariables
  >(key, ChatBenchmarkingAbortDocument, options);
}

export function useChatCreateMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ChatCreateMutation,
      any,
      GraphqlFetcherArgs<any>,
      ChatCreateMutationVariables
    >
  >,
) {
  return useClientSWRMutation<ChatCreateMutation, ChatCreateMutationVariables>(
    key,
    ChatCreateDocument,
    options,
  );
}

export function useChatDeleteMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ChatDeleteMutation,
      any,
      GraphqlFetcherArgs<any>,
      ChatDeleteMutationVariables
    >
  >,
) {
  return useClientSWRMutation<ChatDeleteMutation, ChatDeleteMutationVariables>(
    key,
    ChatDeleteDocument,
    options,
  );
}

export function useChatUpdateTitleMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ChatUpdateTitleMutation,
      any,
      GraphqlFetcherArgs<any>,
      ChatUpdateTitleMutationVariables
    >
  >,
) {
  return useClientSWRMutation<ChatUpdateTitleMutation, ChatUpdateTitleMutationVariables>(
    key,
    ChatUpdateTitleDocument,
    options,
  );
}

export function useUndoChatDeleteMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      UndoChatDeleteMutation,
      any,
      GraphqlFetcherArgs<any>,
      UndoChatDeleteMutationVariables
    >
  >,
) {
  return useClientSWRMutation<UndoChatDeleteMutation, UndoChatDeleteMutationVariables>(
    key,
    UndoChatDeleteDocument,
    options,
  );
}

export function useChatUpdateSubscription(
  sink: Sink<ChatSubscriptionSubscription>,
  variables?: ChatSubscriptionSubscriptionVariables,
) {
  return useWebsocketSubscription<
    ChatSubscriptionSubscription,
    ChatSubscriptionSubscriptionVariables
  >(ChatSubscriptionDocument, sink, variables);
}

export function useMessagesUpdateSubscription(
  sink: Sink<MessageSubscriptionSubscription>,
  variables?: MessageSubscriptionSubscriptionVariables,
): Client {
  return useWebsocketSubscription<
    MessageSubscriptionSubscription,
    MessageSubscriptionSubscriptionVariables
  >(MessageSubscriptionDocument, sink, variables);
}

export function useMessageCreateMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      MessageCreateMutation,
      any,
      GraphqlFetcherArgs<any>,
      MessageCreateMutationVariables
    >
  >,
) {
  return useClientSWRMutation<MessageCreateMutation, MessageCreateMutationVariables>(
    key,
    MessageCreateDocument,
    options,
  );
}

export function useMessageUpdateMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      MessageUpdateMutation,
      any,
      GraphqlFetcherArgs<any>,
      MessageUpdateMutationVariables
    >
  >,
) {
  return useClientSWRMutation<MessageUpdateMutation, MessageUpdateMutationVariables>(
    key,
    MessageUpdateDocument,
    options,
  );
}

export function useContentUpsertByChatMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ContentUpsertByChatMutation,
      any,
      GraphqlFetcherArgs<any>,
      ContentUpsertByChatMutationVariables
    >
  >,
) {
  return useClientSWRMutation<ContentUpsertByChatMutation, ContentUpsertByChatMutationVariables>(
    key,
    ContentUpsertByChatDocument,
    options,
    Service.NODE_INGESTION,
  );
}

export function useUserPromptCreateMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      UserPromptCreateMutation,
      any,
      GraphqlFetcherArgs<any>,
      UserPromptCreateMutationVariables
    >
  >,
) {
  return useClientSWRMutation<UserPromptCreateMutation, UserPromptCreateMutationVariables>(
    key,
    UserPromptCreateDocument,
    options,
  );
}

export function getUseChatQueryKey(
  variables?: ChatQueryVariables,
): GraphqlFetcherArgs<ChatQueryVariables> {
  return { query: ChatDocument, variables };
}

export function getUseChatsQueryKey(
  variables?: ChatsQueryVariables,
): GraphqlFetcherArgs<ChatsQueryVariables> {
  return { query: ChatsDocument, variables };
}

export function getUseMessagesQueryKey(
  variables?: MessagesQueryVariables,
): GraphqlFetcherArgs<MessagesQueryVariables> {
  return { query: MessagesDocument, variables };
}

export function getUseMessageQueryKey(
  variables?: MessageQueryVariables,
): GraphqlFetcherArgs<MessageQueryVariables> {
  return { query: MessageDocument, variables };
}

export function getUseAssistantsQueryKey(
  variables?: AssistantsQueryVariables,
): GraphqlFetcherArgs<AssistantsQueryVariables> {
  return { query: AssistantsDocument, variables };
}

export function getUseAssistantsWithUserPromptsQueryKey(
  variables?: AssistantsWithUserPromptsQueryVariables,
): GraphqlFetcherArgs<AssistantsWithUserPromptsQueryVariables> {
  return { query: AssistantsWithUserPromptsDocument, variables };
}

export function getUseAssistantQueryKey(
  variables?: AssistantQueryVariables,
): GraphqlFetcherArgs<AssistantQueryVariables> {
  return { query: AssistantDocument, variables };
}

export function getUseContentByIdQueryKey(
  variables?: ContentByIdQueryVariables,
): GraphqlFetcherArgs<ContentByIdQueryVariables> {
  return { query: ContentByIdDocument, variables };
}

export function getUseContentByChatQueryKey(
  variables?: ContentByChatQueryVariables,
): GraphqlFetcherArgs<ContentByChatQueryVariables> {
  return { query: ContentByChatDocument, variables };
}

export function getUseChatBenchmarkingStatusQueryKey(
  variables?: ChatBenchmarkingStatusQueryVariables,
): GraphqlFetcherArgs<ChatBenchmarkingStatusQueryVariables> {
  return { query: ChatBenchmarkingStatusDocument, variables };
}

export function useAnalyticsOrdersQuery(
  variables?: AnalyticsOrdersQueryVariables,
  options?: Partial<SWRConfiguration<AnalyticsOrdersQuery>>,
) {
  return useClientSWR<AnalyticsOrdersQuery, AnalyticsOrdersQueryVariables>(
    getUseAnalyticsOrdersQueryKey(variables),
    options,
  );
}

export function getUseAnalyticsOrdersQueryKey(
  variables?: AnalyticsOrdersQueryVariables,
): GraphqlFetcherArgs<AnalyticsOrdersQueryVariables> {
  return { query: AnalyticsOrdersDocument, variables };
}

export function getUseChatBenchmarkingAbortQueryKey(
  variables?: ChatBenchmarkingAbortMutationVariables,
): GraphqlFetcherArgs<ChatBenchmarkingAbortMutationVariables> {
  return { query: ChatBenchmarkingAbortDocument, variables };
}

export function useAnalyticsOrderStartMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      AnalyticsOrderStartMutation,
      any,
      GraphqlFetcherArgs<any>,
      AnalyticsOrderStartMutationVariables
    >
  >,
) {
  return useClientSWRMutation<AnalyticsOrderStartMutation, AnalyticsOrderStartMutationVariables>(
    key,
    AnalyticsOrderStartDocument,
    options,
  );
}

export function useAnalyticsOrderDeleteMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      AnalyticsOrderDeleteMutation,
      any,
      GraphqlFetcherArgs<any>,
      AnalyticsOrderDeleteMutationVariables
    >
  >,
) {
  return useClientSWRMutation<AnalyticsOrderDeleteMutation, AnalyticsOrderDeleteMutationVariables>(
    key,
    AnalyticsOrderDeleteDocument,
    options,
  );
}

export function useMessageCreateSyncMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      MessageCreateSyncMutation,
      any,
      GraphqlFetcherArgs<any>,
      MessageCreateSyncMutationVariables
    >
  >,
) {
  return useClientSWRMutation<MessageCreateSyncMutation, MessageCreateSyncMutationVariables>(
    key,
    MessageCreateSyncDocument,
    options,
  );
}
